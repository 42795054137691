import { Axios } from '../common/config';

const ReferralService = {
  getReferral(accountId) {
    return Axios.get(`/referrals/${accountId}`).then((res) => {
      return res;
    });
  },
  getFriendInReferrals(accountId, data) {
    return Axios.get(`/referrals/${accountId}/friends`, {
      params: { ...data }
    }).then((res) => {
      return res;
    });
  },
  getFriendInReferral(accountId, friendId) {
    return Axios.get(`/referrals/${accountId}/friends/${friendId}`).then((res) => {
      return res;
    });
  },
  validateReferralCode(inviteCode) {
    return Axios.get(`/referrals/${inviteCode}/validate`).then((res) => {
      return res;
    });
  },
  changeCommission(accountId, amount) {
    return Axios.post(`/referrals`, {
      account_id: accountId,
      amount: amount
    }).then((res) => {
      return res;
    });
  }
};
export default ReferralService;
