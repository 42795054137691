<template>
  <div class="inviteFriendList " :class="{ 'd-none': isRoot, 'd-md-block': true }">
    <div class="section-header d-none d-lg-flex">
      <div class="header-title">Bạn bè đã đăng ký</div>
      <router-link :to="$route.meta.redirectBack"
        ><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
          <g transform="translate(-1095 -280)">
            <rect width="12" height="12" transform="translate(1095 280)" fill="#fff" opacity="0" />
            <path
              d="M.152-4.632a.45.45,0,0,0,0,.637l5.5,5.549a.45.45,0,0,0,.637,0L7.036.812a.45.45,0,0,0,0-.637L2.593-4.313,7.036-8.8a.45.45,0,0,0,0-.637l-.742-.742a.45.45,0,0,0-.637,0Z"
              transform="translate(1096.98 290.313)"
              fill="#666"
            />
          </g>
        </svg>
        Quay lại</router-link
      >
    </div>
    <hr class="d-none d-lg-block" />
    <div class="section-content w-100 flex-column">
      <p class="d-flex listHeader">
        <span class="mr-auto">
          Tài khoản
        </span>
        <span>
          Tiền mua
        </span>
      </p>
      <div class="listFriend">
        <div
          class="listFriend__item"
          v-for="(item, index) in listfriendReferral"
          :key="index"
          @click="openInfoFriend(item.friend_id)"
        >
          <span class="listFriend__item__info">
            <span class="name">{{ item.name }}</span
            >{{ ` (*** ${item.phone})` }}
          </span>
          <span class="listFriend__item__totalMoneyUse">
            {{ formatPrice(item.revenue) }}
          </span>
        </div>
      </div>
      <div id="loadingRing" class="d-flex justify-content-center my-2 d-lg-none" v-if="loadingMore">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>

    <!-- PAGINATION -->
    <div class="d-none d-lg-block">
      <div class="d-flex my-2 mt-lg-4">
        <a>
          <b-pagination
            class="custom-pagination"
            pills
            v-model="pagination.currentPage"
            :total-rows="pagination.totalPage"
            hide-ellipsis
            first-number
            last-number
            hide-goto-end-buttons
            :per-page="LIMIT_PAGE"
            next-class="d-none"
            prev-class="d-none"
            :hidden="pagination.totalPage < 1"
          ></b-pagination>
        </a>
      </div>
    </div>
    <CommonModal
      :title="loadingDataFriend ? '' : `${itemFriend.name} (*** ${itemFriend.phone})`"
      @updateOpen="setModalShowInfoFriend"
      :isOpen="modalShowInfoFriend"
      :isHiddenFooter="true"
      :fullHeight="true"
    >
      <div v-if="loadingDataFriend" class="pb-3">
        <div class="d-flex justify-content-center mt-5 mt-lg-2 mb-lg-2 h-100">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="boxInfoMoney">
          <div class="boxInfoMoney__item">
            <p>Tiền vé</p>
            <p>{{ formatPrice(itemFriend.totalRevenue) }}</p>
          </div>
          <div class="boxInfoMoney__item">
            <p>Hoa hồng</p>
            <p class="m-text-primary">{{ formatPrice(itemFriend.totalCommission) }}</p>
          </div>
        </div>
        <div class="boxHistory">
          <p>Hoa hồng theo tháng</p>
          <table>
            <tr>
              <th>Tháng</th>
              <th>Tiền mua vé</th>
              <th>Hoa hồng</th>
            </tr>
            <!-- <div v-if="itemFriend.commissionDetail && itemFriend.commissionDetail.length > 0">
              <tr v-for="(frienddData, index) in itemFriend.commissionDetail" :key="index">
                <td>{{ frienddData.time }}</td>
                <td>{{ formatPrice(frienddData.commission) }}</td>
                <td>{{ formatPrice(frienddData.revenue) }}</td>
              </tr>
            </div>
           -->

            <template v-if="itemFriend.commissionDetail && itemFriend.commissionDetail.length > 0">
              <tr v-for="(frienddData, index) in itemFriend.commissionDetail" :key="index">
                <td>{{ frienddData.time }}</td>
                <td>{{ formatPrice(frienddData.revenue) }}</td>
                <td>{{ formatPrice(frienddData.commission) }}</td>
              </tr>
            </template>
            <tr v-else>
              <td class="text-center py-3 color-grey" colspan="3">Chưa có thông tin</td>
            </tr>
          </table>
        </div>
      </div>
    </CommonModal>
  </div>
</template>

<script>
import ReferralService from '../../../api/referral';
import CommonModal from '../../CommonModal';

export default {
  components: {
    CommonModal
  },

  data() {
    return {
      modalShowInfoFriend: false,
      itemFriend: {},
      pagination: {
        currentPage: 1,
        totalPage: 0
      },
      listfriendReferral: [],
      showFriendId: '',
      LIMIT_PAGE: window.innerWidth > this.getMobileThresshold() ? 10 : 14,
      loadingMore: true,
      timerLoadMore: null,
      timerResize: null,
      saveWidth: '',
      loadingDataFriend: false
    };
  },
  watch: {
    showFriendId: function(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.getDataFriend();
      }
    },
    'pagination.currentPage': function(newVal, oldVal) {
      if (newVal && newVal !== oldVal && window.innerWidth > this.getMobileThresshold()) {
        this.getListFriendData();
      }
    }
  },
  beforeCreate() {},
  created() {
    this.saveWidth = window.innerWidth;
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    this.getListFriendData();
  },
  computed: {
    isRoot() {
      return this.$route.path == '/tai-khoan/' || this.$route.path == '/tai-khoan';
    },
    accountId() {
      return this.$store.getters.getterAccountId;
    }
  },
  methods: {
    setModalShowInfoFriend(value) {
      this.modalShowInfoFriend = value;
    },
    openInfoFriend(friend_id) {
      // this.itemFriend = data;
      this.showFriendId = friend_id;
      this.setModalShowInfoFriend(true);
    },
    async getListFriendData() {
      try {
        const res = await ReferralService.getFriendInReferrals(this.accountId, {
          limit: this.LIMIT_PAGE,
          offset: this.pagination.currentPage
        });

        this.listfriendReferral = res.data.friendList;
        this.pagination.totalPage = res.data.total;
        if (res.data.total > this.LIMIT_PAGE * this.pagination.currentPage) {
          this.loadingMore = true;
        } else {
          this.loadingMore = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getDataFriend() {
      this.loadingDataFriend = true;
      try {
        const res = await ReferralService.getFriendInReferral(this.accountId, this.showFriendId);
        this.itemFriend = { ...res.data.data };
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingDataFriend = false;
      }
    },
    loadMoreListFriendData() {
      if (this.timerLoadMore) clearTimeout(this.timerLoadMore);
      this.timerLoadMore = setTimeout(async () => {
        try {
          const nextPage = this.pagination.currentPage + 1;

          const res = await ReferralService.getFriendInReferrals(this.accountId, {
            limit: this.LIMIT_PAGE,
            offset: nextPage
          });
          this.listfriendReferral = [...this.listfriendReferral, ...res.data.friendList];
          this.pagination.totalPage = res.data.total;

          if (res.data.total > this.LIMIT_PAGE * nextPage) {
            this.loadingMore = true;
          } else {
            this.loadingMore = false;
          }
          this.pagination.currentPage = nextPage;
        } catch (error) {
          console.error(error);
        }
      }, 300);
    },
    handleScroll() {
      if (window.innerWidth > this.getMobileThresshold()) return;
      if (!this.loadingMore) return;
      let bottomOfWindow =
        window.scrollY + window.innerHeight + 100 >= document.documentElement.scrollHeight &&
        window.innerWidth <= this.getMobileThresshold();
      if (bottomOfWindow) {
        this.loadMoreListFriendData();
      }
    },
    handleResize() {
      this.LIMIT_PAGE = window.innerWidth > this.getMobileThresshold() ? 10 : 14;
      const thresshold = this.getMobileThresshold();
      if (
        (window.innerWidth > thresshold && this.saveWidth > thresshold) ||
        (window.innerWidth <= thresshold && this.saveWidth <= thresshold)
      )
        return;
      if (this.timerResize) clearTimeout(this.timerResize);

      this.timerResize = setTimeout(() => {
        // if (window.innerWidth > this.getMobileThresshold()) {
        //   this.pagination.currentPage = 1;
        // } else {
        //   this.pagination.currentPage = 1;
        //   this.getListFriendData();
        // }
        this.pagination.currentPage = 1;
        this.getListFriendData();
        this.saveWidth = window.innerWidth;
      }, 1000);
    }
  }
};
</script>

<style lang="scss">
.inviteFriendList {
  .listHeader {
    color: #666666;
    margin-bottom: 5px;
  }

  .listFriend {
    &__item {
      display: flex;
      justify-content: space-between;
      height: 50px;
      padding: 15px 10px 15px 11px;
      margin-bottom: 5px;

      border-radius: 3px;
      background-color: #ffffff;
      cursor: pointer;

      &:hover {
        background-color: darken(#ffffff, 5%);
      }

      &__totalMoneyUse {
        position: relative;
        padding-right: 18px;
        &::after {
          content: ' ';
          position: absolute;
          right: 0;
          display: inline-block;
          width: 9px;
          height: 9px;
          border-top: 2px solid #999999;
          border-right: 2px solid #999999;
          border-left: none;
          transform: rotate(45deg) translate(-50%, -17%);
          top: 50%;
        }
      }
      &__info {
        .name {
          font-weight: 600;
        }
      }
    }
  }
}

.boxInfoMoney {
  display: flex;
  justify-content: space-around;
  padding-top: 18px;
  border-radius: 3px;
  padding-bottom: 18px;
  background-color: #f7f7f7;
  text-align: center;

  &__item {
    p:first-child {
      color: #666666;
    }

    p:last-child {
      color: #333333;
      font-size: 1.125rem;
      font-weight: 600;
    }
  }
}

.boxHistory {
  margin-left: -20px;
  margin-right: -20px;
  p:first-child {
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 1.125rem;

    color: #666666;
  }

  table {
    width: 100%;

    tr {
      border-bottom: 1px solid #ededed;

      th:nth-child(n + 2),
      td:nth-child(n + 2) {
        text-align: right;
        padding-top: 15px;
        padding-bottom: 15px;
      }

      th:first-child,
      td:first-child {
        padding-left: 15px;
      }

      th:last-child,
      td:last-child {
        padding-right: 15px;
      }

      td:last-child {
        color: $primaryColor;
        font-weight: 600;
      }
    }
  }
}
@media (min-width: 720px) {
}
@media (min-width: map-get($grid-breakpoints, 'lg')) {
  .inviteFriendList {
  }

  .boxHistory {
    margin-left: -20px;
    margin-right: -20px;
    p:first-child {
      padding-left: 20px;
      padding-right: 20px;
    }

    table {
      tr {
        th:first-child,
        td:first-child {
          padding-left: 20px;
        }

        th:last-child,
        td:last-child {
          padding-right: 20px;
        }
      }
    }
  }
}
</style>
